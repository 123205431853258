.App {
  text-align: center;
  padding: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-layout-header {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 30px!important;
  padding: 0px!important;
  background: #161515!important;
}

.ant-layout-sider {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-x: visible;
  overflow-y: auto;
  background-color:  rgb(56, 54, 54)!important;
  max-width: 280px!important;
  width: 280px!important;
}

.ant-layout-sider-children {
  width: 280px!important;
  background-color: rgb(36, 35, 35)!important;
}

.ant-layout-footer {
  background-color:  rgb(36, 35, 35)!important;
}

.ant-layout-content {
  top: 30px;
  bottom: 200px;
  overflow-y: scroll!important;
  background-color:  rgb(36, 35, 35)!important;
}

::-webkit-scrollbar {
  display: none;
}